<i18n>
ru:
  cashback: Кэшбэк
  pointsTextFrom: 'от '
  pointsTextTo: 'до '
  purchaseSum: Общая сумма сделанных покупок
ua:
  cashback: Кэшбек
  pointsTextFrom: 'від '
  pointsTextTo: 'до '
  purchaseSum: Загальна сума покупок
us:
  cashback: Cashback
  pointsTextFrom: 'from '
  pointsTextTo: 'to '
  purchaseSum: Total purchases
</i18n>

<template>
  <div
    class="v-pb-xs"
    v-html="content.split('{{pointsPage_ranks}}')[0]"
  />
  <div
    v-if="appConfig.VueSettingsPreRun.BonusSystemLayout"
    class="v-d-flex v-flex-column v-points-rank__detailed"
  >
    <div
      v-for="(rank, index) in ranks"
      :key="rank.ID"
      class="v-d-flex v-mb-md v-points-rank__detailed--wrapper"
    >
      <div class="v-points-rank__detailed--left-part">
        <span
          class="v-mr-xs v-points-rank__detailed--title"
          v-html="translate('points.cashback')"
        />
        <span
          class="v-points-rank__detailed--percent"
          v-html="sanitize(rank.Title)"
        />
      </div>
      <div class="v-points-rank__detailed--right-part">
        <span
          class="v-mr-xs"
          v-html="translate('points.purchaseSum')"
        />
        <div class="v-font-weight-500 v-d-flex v-flex-row">
          <span
            class="v-mr-xs"
            v-html="translate('points.pointsTextFrom')"
          />
          <common-currency
            class="v-d-flex v-flex-row-no-wrap v-user-ranks-benchmark"
            :amount="rank.Benchmark"
          />
          <template v-if="index < ranks.length - 1">
            <span
              class="v-ml-xs v-mr-xs"
              v-html="translate('points.pointsTextTo')"
            />
            <common-currency
              class="v-d-flex v-flex-row-no-wrap v-user-ranks-benchmark"
              :amount="ranks[index + 1].Benchmark - 1"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="v-scrollbar v-points-rank__concise--wrapper v-d-flex v-mb-sm"
  >
    <div
      v-for="(rank, index) in ranks"
      :key="rank.ID"
      class="v-points-rank__concise v-overflow-hidden v-d-flex"
    >
      <div class="v-points-rank__concise--element v-px-lg">
        <div class="v-points-rank__concise--bar">
          <common-progress-bar-radial
            :circle-size="index < ranks.length - 1 ? 5 : 40"
            :milestones="index < ranks.length - 1 ? [0, 100] : [100]"
            :size="40"
            :value="(index + 1) * (100 / ranks.length)"
          >
            <div class="v-d-flex v-w-100 v-h-100 v-align-items-center v-justify-content-center">
              <span
                :class="
                  index < ranks.length - 1
                    ? 'v-points-rank__concise--title'
                    : 'v-points-rank__concise--title v-points-rank__concise--title__filled'
                "
                v-html="sanitize(rank.Title)"
              />
            </div>
          </common-progress-bar-radial>
        </div>
        <div class="v-d-flex v-flex-column v-justify-content-center">
          <div class="v-d-flex v-flex-row-no-wrap v-body-text-color-light v-font-weight-500">
            <span
              class="v-mr-xs"
              v-html="translate('points.pointsTextFrom')"
            />
            <common-currency
              class="v-d-flex v-flex-row-no-wrap v-user-ranks-benchmark"
              :amount="rank.Benchmark"
            />
          </div>
          <div
            v-if="index < ranks.length - 1"
            class="v-d-flex v-flex-row-no-wrap v-body-text-color-light v-font-weight-500"
          >
            <span
              class="v-mr-xs"
              v-html="translate('points.pointsTextTo')"
            />
            <common-currency
              class="v-d-flex v-flex-row-no-wrap v-user-ranks-benchmark"
              :amount="ranks[index + 1].Benchmark - 1"
            />
          </div>
        </div>
      </div>
      <div class="v-points-rank__concise--line-wrapper">
        <hr
          v-if="index < ranks.length - 1"
          class="v-points-rank__concise--line"
        />
      </div>
    </div>
  </div>
  <div
    v-if="content.includes('{{pointsPage_ranks}}')"
    class="v-pb-xs"
    v-html="content.split('{{pointsPage_ranks}}')[1]"
  />
</template>

<script setup lang="ts">
import type { Rank } from '~types/accountStore'

const { content } = defineProps<{
  title: string
  content: string
}>()

const appConfig = useAppConfig()

const accountStore = useAccountStore()

const ranks = computed<Rank[]>(() => accountStore.RanksStat.data?.Ranks ?? [])

const { sanitize, translate } = useI18nSanitized()
onMounted(() => {
  if (import.meta.client) accountStore.initRanks()
})
</script>

<style lang="scss">
@use 'assets/mixins';
@use 'assets/variables';

.v-points-rank {
  &__detailed {
    flex: 1 1 0;
    font-size: 1.2rem;

    &--left-part {
      border-radius: variables.$BorderRadius 5px 5px variables.$BorderRadius;
      border: 2px solid variables.$PrimaryBackgroundColor;
      display: flex;
      flex-direction: row;
      height: 4.285rem;
    }

    &--right-part {
      border-radius: variables.$BorderRadius;
      border: 2px solid variables.$BorderColor;
      display: flex;
      flex-direction: row;
      padding: 1.425rem 1.15rem;

      @include mixins.md {
        padding: 0;
        border: none;
        display: block;
      }
    }

    &--title {
      margin: 1.425rem 1.15rem;
    }

    &--wrapper {
      gap: 4rem;
    }

    &--percent {
      border-radius: 50%;
      background: variables.$PrimaryBackgroundColor;
      color: variables.$PrimaryColor;
      padding: 1.65rem 0.7rem;
      font-weight: 600;

      width: 4.285rem;
      height: 4.285rem;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: -2.1rem;
      margin-top: -2px;
      margin-bottom: -2px;
    }
  }

  &__concise {
    font-size: 1.125rem;
    flex-direction: row;
    justify-content: start;
    min-width: 200px;

    &--bar {
      margin-bottom: 1.25rem;

      @include mixins.md {
        margin-bottom: 0;
        margin-right: 1.25rem;
      }
    }

    &--element {
      display: flex;
      flex-direction: column;
      min-width: 130px;

      @include mixins.md {
        flex-direction: row;
      }
    }

    @include mixins.md {
      flex-direction: column;
      align-items: center;
    }

    &--wrapper {
      flex-direction: row;
      overflow-x: auto;

      @include mixins.md {
        flex-direction: column;
      }
    }

    &--title {
      font-weight: 600;

      &__filled {
        color: variables.$PrimaryColor;
      }
    }

    &--line-wrapper {
      @include mixins.md {
        margin-left: -90px;
      }
    }

    &--line {
      width: 140px;
      height: 2px;
      color: variables.$PrimaryBackgroundColor;
      opacity: 0.8;
      margin-top: 40px;

      @include mixins.xl {
        height: 2px;
        width: 120px;
      }

      @include mixins.lg {
        height: 2px;
        width: 100px;
      }

      @include mixins.md {
        height: 60px;
        width: 2px;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      @include mixins.sm {
        height: 40px;
        width: 2px;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      @include mixins.xs {
        height: 30px;
        width: 2px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
